import React from "react"
import logo from "../assets/css/images/contactlogo.png"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default () => (
  <Layout isHome={false} pageId="contactpage" logo={logo}>
    <SEO title="Contact" />
    <div id="content">
      <div id="contact_form">
        <p className="contact-intro">
          Thank you for your interest. If you have any questions regarding our
          services or solutions, feel free to use the form below. We will do our
          best to give you a quick reply.
        </p>

        <form name="contact" id="form_contact" action="" method="post">
          <div className="setone">
            <label className="required" htmlFor="subject">
              Subject * :
            </label>{" "}
            <input
              className="validate[required,minSize[5]]"
              type="text"
              name="subject"
              id="subject"
            />
            <label className="required" htmlFor="yourname">
              Name * :
            </label>{" "}
            <input
              className="validate[required,minSize[5]]"
              type="text"
              name="yourname"
              id="yourname"
            />
            <label className="required" htmlFor="email">
              Email * :
            </label>{" "}
            <input
              className="validate[required,custom[email]]"
              type="text"
              name="email"
              id="email"
            />
          </div>
          <div className="settwo">
            <label htmlFor="company">Company:</label>{" "}
            <input type="text" name="company" id="company" />
            <label htmlFor="phone">Phone:</label>{" "}
            <input
              className="validate[custom[phone]]"
              type="text"
              name="phone"
              id="phone"
            />
            <label htmlFor="website">Website:</label>{" "}
            <input
              className="validate[custom[url]]"
              type="text"
              name="website"
              id="website"
            />
          </div>
          <label className="special" htmlFor="message">
            Message*:
          </label>
          <textarea
            name="message"
            id="message"
            className="validate[required,minSize[20]]"
            placeholder="Your message here..."
          />
          <input type="submit" value="Send Message" />
        </form>
        <p className="note">
          To sent the form you have to fill the fields marked with the little{" "}
          <span>*</span> star.
        </p>
        <p className="fin">Thank you for your time.</p>
      </div>

      <div id="contact_info">
        <div className="c_i_gradient">
          <ul className="c_i_list">
            <li className="title">contact info</li>
            <li>Phone: +40 0756532448</li>
            <li>
              Email: <a href="/">office at getfresh.ro</a>
            </li>
            <li>
              Web: <a href="/">www.getfresh.ro</a>
            </li>
          </ul>
        </div>
      </div>
    </div>{" "}
  </Layout>
)
